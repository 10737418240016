


























































































































































































































































































// @ is an alias to /src
import { MutationPayload } from 'vuex';
import Checkbox from '@/components/form/checkbox.vue';
import DatePicker from '@/components/form/date-picker.vue';
import Input from '@/components/form/input.vue';
import Select from '@/components/form/select.vue';
import Textarea from '@/components/form/textarea.vue';
import Offcanvas from '@/components/offcanvas.vue';
import Loading from '@/components/loading.vue';
import router from '@/router';

import store from '@/store';
import { getAttendingAnswers, getVisitorById } from '@/api/visitors';
import { getEventInfo } from '@/api/events';
import { getFileById } from '@/api/files';
import isJson from '@/utils/isJson';
import getYoutubeId from '@/utils/getYoutubeId';
import { IAttendingAnswer, IVisitor } from '@/models/visitor';
import { createRocketInstantMessage, getRocketUser } from '@/api/chat';
import { IRocketRegisterResponse } from '@/models/chat';
import { computed, defineComponent, reactive } from '@vue/composition-api';
import useContext, { IUseContext } from '@/composition/context';

export default defineComponent({
  components: {
    Offcanvas,
    Input,
    Checkbox,
    Textarea,
    Select,
    Loading,
    DatePicker,
  },
  data() {
    return {
      fileLoading: false,
    };
  },
  setup() {
    const { translations, contextEvent, contextVisitor } = useContext() as unknown as IUseContext;
    const event = parseInt(router.currentRoute.params.event, 10);
    const state = reactive({
      loading: false,
      visitor: {} as IVisitor,
      visitorAnswers: [] as IAttendingAnswer[],
      rocketChatUser: {} as IRocketRegisterResponse,
      videoUrl: '',
      visitorId: 0,
    });

    const visitorInitial = computed(() => state.visitor.name && state.visitor.name.charAt(0));

    return { translations, event, contextEvent, state, visitorInitial, contextVisitor };
  },
  created() {
    this.state.visitorId = parseInt(this.$route.params.visitor, 10);
    this.event = parseInt(this.$route.params.event, 10);
    if (this.contextEvent.slug) {
      this.getVisitorInformation();
    } else {
      const unsubscribe = store.subscribe((mutation: MutationPayload) => {
        if (mutation.type === 'setContextEvent') {
          this.getVisitorInformation();
          unsubscribe();
        }
      });
    }
    store.subscribe((mutation: MutationPayload) => {
      if (mutation.type === 'setLanguage') {
        this.getVisitorInformation();
      }
    });
  },
  methods: {
    makeJson(value: string | JSON) {
      // check if string is json
      if (typeof value === 'string' && isJson(value)) {
        return JSON.parse(value);
      }
      return value;
    },

    getVisitorInformation() {
      this.state.visitorAnswers = [];
      this.state.loading = true;
      getVisitorById(this.state.visitorId).then((response) => {
        this.state.visitor = response.data;
      });
      getEventInfo(this.contextEvent).then((response) => {
        let { fields } = response.data;
        if (fields) {
          fields = fields.filter((field) => !field.attending_field.hide_from_visitors);
          getAttendingAnswers(this.state.visitorId).then((response) => {
            this.state.visitorAnswers = [];

            response.data.results.map(async (answer) => {
              if (fields) {
                const field = fields.find(
                  (field) => answer && answer.event_attending_field === field.id,
                );
                if (field && answer.answer) {
                  // Text, Textarea, Select, Radio button todo: covered - take nonformatted answer

                  // Files (Validation Image, Square Image)
                  if (
                    ['image', 'square_image'].includes(field.attending_field.validation) &&
                    answer.answer !== ''
                  ) {
                    const fileResponse = await getFileById(answer.answer as any);
                    const { file } = fileResponse.data;
                    answer.answer = file;
                  }

                  // Checkbox
                  if (answer.answer && field.attending_field.field_type === 'checkbox') {
                    this.extractSelectedCheckboxes(answer);
                  }

                  // Youtube embedded link (Custom fields)
                  if (field.attending_field.title === 'BridgeEmbeddedVideo') {
                    this.state.videoUrl = this.getYoutubeEmbededLink(answer.answer);
                  }

                  if (
                    field.attending_field.title &&
                    field.attending_field.title.includes('Bridge')
                  ) {
                    return;
                  }

                  const visitorAnswer = {
                    ...answer,
                    title: field.attending_field.title,
                    field_type: field.attending_field.field_type,
                    validation: field.attending_field.validation,
                    hide_on_media_channel_message:
                      field.attending_field.hide_on_media_channel_message, // temporary
                    answer: isJson(answer.answer) ? JSON.parse(answer.answer) : answer.answer,
                  };

                  this.state.visitorAnswers.push(visitorAnswer);
                }
              }
            });
            this.state.loading = false;
          });
        }
      });
    },

    extractSelectedCheckboxes(answer: any) {
      try {
        const options = JSON.parse(answer.answer);
        const checkedOptions = options.filter((option: any) => option.checked);
        answer.answer = JSON.stringify(checkedOptions);
      } catch (error) {
        // Could not extract values
        answer.answer = '';
      }
    },

    answerIsEmpty(answer: any) {
      try {
        if (answer.field_type === 'checkbox') {
          return answer.answer.filter((a: any) => a.checked).length < 1;
        }
        return answer.answer.trim() === '';
      } catch (error) {
        // do nothing
      }
      return false;
    },

    downloadFile(fileId: number | string) {
      this.fileLoading = true;
      getFileById(fileId).then((response) => {
        this.fileLoading = false;
        const { file, name } = response.data;
        const link = document.createElement('a');
        link.href = file;
        link.setAttribute('download', name || 'file');
        link.setAttribute('target', '_blank');
        link.click();
      });
    },

    getYoutubeEmbededLink(answer: string) {
      let embeddedLink = '';
      try {
        if (answer) {
          const youtubeId = getYoutubeId(answer);

          if (youtubeId) {
            embeddedLink = `https://www.youtube.com/embed/${youtubeId}`;
          }
        }
      } catch (error) {
        // Invalid link
        embeddedLink = '';
      }
      return embeddedLink;
    },

    openChat(user: number) {
      getRocketUser(`u${user}`)
        .then((response) => {
          const { user } = response.data;
          createRocketInstantMessage(user.username).then((response) => {
            const roomId = response.data.room.rid;
            store.commit('setChatRoom', { rid: roomId, type: 'im', roomName: `${user.name}` });
            store.commit('setChatOpen');
          });
        })
        .catch((err) => {
          console.log(err);
          const errors = err.response.data;
          if (errors && errors.length > 0) {
            errors.forEach((error: string) => {
              store.commit('addPopup', {
                message: error,
                type: 'danger',
                autohide: true,
              });
            });
          } else {
            store.commit('addPopup', {
              message: err.nessage,
              type: 'danger',
              autohide: true,
            });
          }
        });
    },

    discard() {
      router.go(-1);
    },
  },
  watch: {
    $route(next, prev) {
      if (prev.path.includes(`/profile/${this.contextVisitor.id}/edit`) && this.contextEvent.slug) {
        this.getVisitorInformation();
      }
    },
  },
});
